import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './components/App';

import * as serviceWorker from './serviceWorker';

import './index.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://dde64eab3d564c28a57599b0fdf00e33@o777343.ingest.sentry.io/5797738",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: 'uat',
    release: 'ns-front-end@2.0.0',
  });
}

declare module '@material-ui/core/styles/createPalette' {
  interface CommonColors {
    black: string;
    white: string;
    green: string;
    red: string;
    neutral: string;
  }
  interface Palette {
    border: string;
  }
}

declare module '@material-ui/core/styles/createSpacing' {
  interface Spacing {
    unit: string;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
export type { UserInfo } from './data/mixins/WithCognitoUser';
